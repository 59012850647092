import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import AddTitlePerfection from './pages/AddTitlePerfection';
import AgentDetails from './pages/AgentDetails';
import CofO from './pages/CofO';
import DashboardAppPage from './pages/DashboardAppPage';
import EditTitlePerfection from './pages/EditTitlePerfection';
import IssuedSurvey from './pages/IssuedSurvey';
import LandDocument from './pages/LandDocument';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import PropertyDocument from './pages/PropertyDocument';
import ResolvedSurveys from './pages/ResolvedSurveys';
import CofODetails from './pages/SingleCofODetails';
import SurveyLandDocument from './pages/SurveyLandDocument';
import SurveyPropertyDocument from './pages/SurveyPropertyDocument';
import SurveyorDetails from './pages/SurveyorDetails';
import TitlePerfections from './pages/TitlePerfections';
import UnresolvedSurveys from './pages/UnresolvedSurveys';
import UnverifiedAgents from './pages/UnverifiedAgents';
import UnverifiedDocuments from './pages/UnverifiedDocuments';
import User from './pages/User';
import UserPage from './pages/UserPage';
import VerifiedAgents from './pages/VerifiedAgents';
import VerifiedDocuments from './pages/VerifiedDocuments';
import VerifiedUserPage from './pages/VerifiedUserPage';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useSelector((state) => state.adminAuth);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'unverifiedDocuments', element: <UnverifiedDocuments /> },
        { path: 'verifiedDocuments', element: <VerifiedDocuments /> },
        { path: 'unresolvedSurveys', element: <UnresolvedSurveys /> },
        { path: 'resolvedSurveys', element: <ResolvedSurveys /> },
        { path: 'completedSurvey', element: <ResolvedSurveys /> },
        { path: 'verifiedusers', element: <VerifiedUserPage /> },
        { path: 'issuedSurvey', element: <IssuedSurvey /> },
        { path: 'users/user/:userId', element: <User /> },
        { path: 'documents/property_document/:docId', element: <PropertyDocument /> },
        { path: 'documents/land_document/:docId', element: <LandDocument /> },
        { path: 'documents/survey_property_document/:docId', element: <SurveyPropertyDocument /> },
        { path: 'documents/survey_land_document/:docId', element: <SurveyLandDocument /> },
        { path: 'surveyors/surveyor/:surveyorId', element: <SurveyorDetails /> },
        { path: 'agents/agent/:agentId', element: <AgentDetails /> },
        { path: 'unVerifiedAgents', element: <UnverifiedAgents /> },
        { path: 'verifiedAgents', element: <VerifiedAgents /> },
        { path: 'titlePerfection/add', element: <AddTitlePerfection /> },
        { path: 'titlePerfection/edit', element: <EditTitlePerfection /> },
        { path: 'titlePerfection', element: <TitlePerfections /> },
        { path: 'cofo', element: <CofO /> },
        { path: 'cofo/:id', element: <CofODetails /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
