import React, { useEffect } from 'react';
import { Table, Button, Typography, Space, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { getAllTitlePerfectionPrice } from '../features/titlePerfection/titlePerfectionSlice';
import { reset } from '../features/propertydocuments/PropertydocumentSlice';
import Loader from '../components/loader';

const { Title } = Typography;

const TitlePerfections = () => {
  const dispatch = useDispatch();
  const notFound = '/assets/images/covers/notfound.svg';
  const { titlePerfections, isLoading, isError, message } = useSelector((state) => state.titlePerfection);

  useEffect(() => {
    dispatch(getAllTitlePerfectionPrice());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  // Search input handler for each column
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Country',
      dataIndex: 'Country',
      key: 'country',
      width: 120,
      ...getColumnSearchProps('Country'),
      sorter: (a, b) => a.Country.localeCompare(b.Country),
    },
    {
      title: 'State',
      dataIndex: 'State',
      key: 'state',
      width: 120,
      ...getColumnSearchProps('State'),
      sorter: (a, b) => a.State.localeCompare(b.State),
    },
    {
      title: 'Direct Assessment',
      dataIndex: 'DirectAssessment',
      key: 'directAssessment',
      width: 150,
      sorter: (a, b) => a.DirectAssessment - b.DirectAssessment,
      render: (value) => `₦${value?.toLocaleString() || '0'}`,
    },
    {
      title: 'Stamps Duty',
      dataIndex: 'StampsDuty',
      key: 'stampsDuty',
      width: 150,
      sorter: (a, b) => a.StampsDuty - b.StampsDuty,
      render: (value) => `₦${value?.toLocaleString() || '0'}`,
    },
    {
      title: 'Admin Charge',
      dataIndex: 'AdminCharge',
      key: 'adminCharge',
      width: 150,
      sorter: (a, b) => a.AdminCharge - b.AdminCharge,
      render: (value) => `₦${value?.toLocaleString() || '0'}`,
    },
    {
      title: 'Development Levy',
      dataIndex: 'DevelopmentLevy',
      key: 'developmentLevy',
      width: 150,
      sorter: (a, b) => a.DevelopmentLevy - b.DevelopmentLevy,
      render: (value) => `₦${value?.toLocaleString() || '0'}`,
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (_, record) => (
        <Link to={`/dashboard/titlePerfection/edit?state=${record.State}`}>
          <Button type="primary" size="small" className="bg-blue-600">
            View
          </Button>
        </Link>
      ),
    },
  ];

  // Transform data to match the table structure
  const transformedData = titlePerfections?.map((item, index) => ({
    key: index,
    id: index + 1,
    ...item,
  }));

  return (
    <>
      <Helmet>
        <title> Documents | Lock.your.Land Dashboard </title>
      </Helmet>

      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Title level={4}>Title Perfection</Title>
          <Link to="/dashboard/titlePerfection/add">
            <Button type="primary" className="bg-blue-600" icon={<PlusOutlined />}>
              Add Title Perfection
            </Button>
          </Link>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : titlePerfections?.length < 1 ? (
          <div className="flex justify-center items-center">
            <img src={notFound} alt="not found" className="w-72" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={transformedData}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ x: 1200 }}
            rowSelection={{
              type: 'checkbox',
            }}
          />
        )}
      </div>
    </>
  );
};

export default TitlePerfections;
