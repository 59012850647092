import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import cofoService from './c_of_o_Service';

const initialState = {
  cofos: [],
  singleCofO: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getAllCofO = createAsyncThunkWithHandler('cofo/getAllCofO', async (_, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const adminId = thunkAPI.getState().adminAuth.user.id;
  const response = await cofoService.getAllCofO(token, adminId);
  return response;
});

export const getSingleCofO = createAsyncThunkWithHandler('cofo/getSingleCofO', async (id, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const adminId = thunkAPI.getState().adminAuth.user.id;
  const response = await cofoService.getSingleCofO(token, { cofoId: id, adminId });
  return response;
});

export const assignSingleCofO = createAsyncThunkWithHandler('cofo/assignSingleCofO', async (cofoData, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const adminId = thunkAPI.getState().adminAuth.user.id;
  const response = await cofoService.assignSingleCofO(token, { ...cofoData, adminId });
  return response;
});

const cofoSlice = createSlice({
  name: 'cofo',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.cofos = action.payload;
      })
      .addCase(getAllCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getSingleCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleCofO = action.payload;
      })
      .addCase(getSingleCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })

      .addCase(assignSingleCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'CofO Assigned Succesfully';
      })
      .addCase(assignSingleCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = cofoSlice.actions;
export default cofoSlice.reducer;
