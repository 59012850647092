import React from 'react';
import { Paper, Container, Typography, Button, Grid, Box, ThemeProvider, createTheme } from '@mui/material';
import { Card, Timeline, Tag, message } from 'antd';
import {
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EnvironmentOutlined,
  CalendarOutlined,
  UserOutlined,
  PhoneOutlined,
  BankOutlined,
  FileTextOutlined,
  DollarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import QRCode from 'qrcode.react';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// Create custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: '#1976d2',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      color: '#1976d2',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
      color: '#64748b',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          padding: '10px 20px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
});

// Reusable components
const Section = ({ title, children, icon, status = null }) => (
  <Card
    className="mb-6 shadow-md"
    headStyle={{
      backgroundColor: '#f8fafc',
      borderBottom: '1px solid #e2e8f0',
      padding: '16px 24px',
    }}
    title={
      <Box className="flex items-center gap-4 whitespace-normal">
        <span className="flex-shrink-0">{icon}</span>
        <Typography
          variant="h3"
          component="div"
          className="flex-grow"
          style={{ wordBreak: 'break-word', marginTop: '-1.5%', fontSize: '14px' }}
        >
          {title}
        </Typography>
        {status !== null && (
          <span className="flex-shrink-0">
            <StatusBadge status={status} />
          </span>
        )}
      </Box>
    }
    bodyStyle={{ padding: '24px' }}
  >
    {children}
  </Card>
);

const InfoField = ({ label, value, icon }) => (
  <Box className="mb-4 flex items-start gap-3">
    <Box className="text-blue-600 mt-1">{icon}</Box>
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      <Typography variant="body1" className="font-medium">
        {value || '—'}
      </Typography>
    </Box>
  </Box>
);

const StatusBadge = ({ status }) => (
  <Tag
    icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
    color={status ? 'success' : 'error'}
    className="px-3 py-1 flex items-center gap-2 text-sm"
  >
    {status ? 'Suitable for Purchase' : 'Not Recommended'}
  </Tag>
);

const CompanyHeader = ({ documentId }) => (
  <Box className="flex justify-between items-center mb-8 pb-4 border-b-2 border-blue-600">
    <Box>
      <Typography variant="h4" gutterBottom>
        <span className="text-green-600 font-bold sm:text-2xl text-md">Lock.</span>
        <span className="font-bold text-neutral-900 sm:text-2xl text-md">your</span>
        <span className="text-blue-800 font-bold sm:text-2xl text-md">.land</span>
      </Typography>
      <Box className="flex gap-4 text-gray-600 italic">
        <Typography>Due Diligence</Typography>
        <Typography>•</Typography>
        <Typography>Due Process</Typography>
        <Typography>•</Typography>
        <Typography>Dependable</Typography>
      </Box>
    </Box>
    <Box className="bg-white p-4 rounded-lg shadow-md">
      <QRCode value={documentId} size={100} level="H" />
    </Box>
  </Box>
);

// Main Report Component
const Report = ({ documentReport }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount);
  };

  const handleDownloadPDF = async () => {
    message.loading({ content: 'Generating PDF...', key: 'pdfGeneration' });

    try {
      const element = document.getElementById('report-content');
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: true,
        scrollY: -window.scrollY,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        onclone: (document) => {
          // Ensure proper rendering of elements in the cloned document
          const element = document.getElementById('report-content');
          if (element) {
            element.style.padding = '20px';
            element.style.background = '#ffffff';
          }
        },
      });

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new JsPDF({
        orientation: imgHeight > imgWidth ? 'portrait' : 'landscape',
        unit: 'mm',
        format: [imgWidth, imgHeight],
      });

      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
      pdf.save(`Property-Search-Report-${documentReport.clientName}-${new Date().toISOString().split('T')[0]}.pdf`);

      message.success({ content: 'PDF downloaded successfully!', key: 'pdfGeneration', duration: 2 });
    } catch (error) {
      console.error('Error generating PDF:', error);
      message.error({ content: 'Failed to generate PDF. Please try again.', key: 'pdfGeneration', duration: 3 });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className="py-8">
        <Paper elevation={0} className="p-8 bg-gray-50" style={{ overflowX: 'hidden' }}>
          <div
            id="report-content"
            className="max-w-full"
            style={{
              minHeight: '100%',
              width: '100%',
              pageBreakInside: 'auto',
            }}
          >
            <CompanyHeader documentId={documentReport?.docId} />

            <Box className="flex justify-between items-center mb-6 flex-wrap gap-4">
              <Typography variant="h2">Property Search Report</Typography>
              <Button variant="contained" onClick={handleDownloadPDF} startIcon={<DownloadOutlined />} size="large">
                Download PDF
              </Button>
            </Box>

            <Section title="Search Information" icon={<FileTextOutlined className="text-blue-600" />}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<EnvironmentOutlined />}
                    label="Search Location"
                    value={documentReport?.searchConductedAt}
                  />
                  <InfoField
                    icon={<CalendarOutlined />}
                    label="Date of Search"
                    value={formatDate(documentReport?.dateOfSearch)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoField icon={<UserOutlined />} label="Conducted By" value={documentReport?.searchConductedBy} />
                  <InfoField icon={<TeamOutlined />} label="Department Head" value={documentReport?.departmentHead} />
                  <InfoField
                    icon={<BankOutlined />}
                    label="Department/Room"
                    value={documentReport?.departmentAndRoomNumber}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section title="Professional Information" icon={<BankOutlined className="text-blue-600" />}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoField icon={<UserOutlined />} label="Lawyer's Name" value={documentReport?.lawyerName} />
                  <InfoField icon={<PhoneOutlined />} label="Phone Number" value={documentReport?.phoneNumber} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoField icon={<FileTextOutlined />} label="SC Number" value={documentReport?.scNumberCallYear} />
                  <InfoField
                    icon={<EnvironmentOutlined />}
                    label="Office Address"
                    value={documentReport?.officeAddress}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section title="Property Details" icon={<BankOutlined className="text-blue-600" />}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoField icon={<EnvironmentOutlined />} label="Location" value={documentReport?.location} />
                  <InfoField
                    icon={<FileTextOutlined />}
                    label="Size/Measurement"
                    value={`${documentReport?.sizeMeasurement} ${documentReport?.measurementScale}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoField
                    icon={<DollarOutlined />}
                    label="Purchase Price"
                    value={formatCurrency(documentReport?.purchasePrice)}
                  />
                  <InfoField icon={<UserOutlined />} label="Client Name" value={documentReport?.clientName} />
                  <InfoField
                    icon={<PhoneOutlined />}
                    label="Client Contact"
                    value={documentReport?.clientContactInformation}
                  />
                </Grid>
              </Grid>
            </Section>

            <Section
              title="Search Findings"
              icon={<FileTextOutlined className="text-blue-600" />}
              status={documentReport?.isSuitableForPurchase === 'true'}
            >
              <Timeline
                items={[
                  {
                    color: 'blue',
                    children: (
                      <Box>
                        <Typography variant="subtitle1" className="font-semibold">
                          Ownership Details
                        </Typography>
                        <Box className="ml-4 mt-2">
                          <InfoField
                            label="Registered Owner(s)"
                            value={documentReport?.registeredOwners}
                            icon={<UserOutlined />}
                          />
                          <InfoField
                            label="Title Documents"
                            value={documentReport?.titleDocuments}
                            icon={<FileTextOutlined />}
                          />
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    color: documentReport?.hasEncumbrances === 'true' ? 'red' : 'green',
                    children: (
                      <Box>
                        <Typography variant="subtitle1" className="font-semibold">
                          Encumbrances
                        </Typography>
                        <Typography className="mt-2">
                          {documentReport?.hasEncumbrances === 'true'
                            ? documentReport?.encumbrancesDetails
                            : 'No encumbrances found'}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    color: documentReport?.hasDisputes === 'true' ? 'red' : 'green',
                    children: (
                      <Box>
                        <Typography variant="subtitle1" className="font-semibold">
                          Disputes/Litigation
                        </Typography>
                        <Typography className="mt-2">
                          {documentReport?.hasDisputes === 'true'
                            ? documentReport?.disputesDetails
                            : 'No disputes or litigation found'}
                        </Typography>
                      </Box>
                    ),
                  },
                  {
                    color: documentReport?.isSubjectToAcquisition === 'true' ? 'red' : 'green',
                    children: (
                      <Box>
                        <Typography variant="subtitle1" className="font-semibold">
                          Government Acquisition Status
                        </Typography>
                        <Typography className="mt-2">
                          {documentReport?.isSubjectToAcquisition === 'true'
                            ? documentReport?.acquisitionDetails
                            : 'No government acquisition found'}
                        </Typography>
                      </Box>
                    ),
                  },
                ]}
              />

              {(documentReport?.issues || documentReport?.notRecommendedReason) && (
                <Box className="bg-red-50 p-6 rounded-lg mt-6">
                  <Typography variant="h6" color="error" className="mb-2">
                    Issues Requiring Attention
                  </Typography>
                  <Typography color="error">
                    {documentReport?.issues || documentReport?.notRecommendedReason}
                  </Typography>
                </Box>
              )}
            </Section>

            <Section title="Certification" icon={<FileTextOutlined className="text-blue-600" />}>
              <Box className="bg-gray-50 p-6 rounded-lg border border-blue-200">
                <Typography paragraph>
                  I, <strong>{documentReport?.lawyerName}</strong>, hereby certify that this search was conducted at the{' '}
                  {documentReport?.searchConductedAt} Land Registry, Department{' '}
                  {documentReport?.departmentAndRoomNumber}, on {formatDate(documentReport?.dateOfSearch)}. I confirm
                  that the information provided in this report is accurate and complete to the best of my knowledge as
                  of the search date.
                </Typography>
                <Box className="flex justify-between items-center mt-6">
                  <Typography variant="subtitle1">
                    <strong>Report Generated:</strong> {formatDate(documentReport?.createdAt)}
                  </Typography>
                  <Box className="border-b-2 border-blue-600 flex justify-center w-[25%]">
                    <img src={documentReport?.signature} alt="" className="w-[65%]" />
                  </Box>
                </Box>
              </Box>
            </Section>
          </div>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default Report;
