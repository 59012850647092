import React, { useEffect } from 'react';
import { Table, Button, Typography, Space, Input, Tag, Tooltip } from 'antd';
import { PlusOutlined, SearchOutlined, FileOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { getAllCofO } from '../features/c_of_o/c_of_o_Slice';
import { reset } from '../features/propertydocuments/PropertydocumentSlice';
import Loader from '../components/loader';

const { Title } = Typography;

const CofO = () => {
  const dispatch = useDispatch();
  const { cofos, isLoading, isError, message } = useSelector((state) => state.cofo);

  useEffect(() => {
    dispatch(getAllCofO());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const getColumnSearchProps = (dataIndex, nestedField) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const field = nestedField ? record[dataIndex][nestedField] : record[dataIndex];
      return field ? field.toString().toLowerCase().includes(value.toLowerCase()) : '';
    },
  });

  const getStatusTag = (status, type = 'status') => {
    const statusColors = {
      status: {
        pending: 'gold',
        under_review: 'processing',
        approved: 'success',
        rejected: 'error',
        assigned: 'blue',
      },
      payment: {
        pending: 'warning',
        paid: 'success',
      },
      upload: {
        pending: 'default',
        uploaded: 'success',
      },
    };

    const statusText = status
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return <Tag color={statusColors[type][status]}>{statusText}</Tag>;
  };

  const columns = [
    {
      title: 'Application ID',
      dataIndex: ['CofO', 'applicationId'],
      key: 'applicationId',
      ...getColumnSearchProps('CofO', 'applicationId'),
      width: 160,
      fixed: 'left',
    },
    {
      title: 'Full Name',
      key: 'fullName',
      render: (record) => `${record.user.Firstname} ${record.user.Lastname}`,
      ...getColumnSearchProps('user', 'Firstname'),
      width: 150,
    },
    {
      title: 'Property Type',
      dataIndex: ['CofO', 'propertyType'],
      key: 'propertyType',
      filters: [
        { text: 'Land', value: 'land' },
        { text: 'Building', value: 'building' },
      ],
      onFilter: (value, record) => record.CofO.propertyType === value,
      width: 120,
      render: (propertyType) => propertyType.charAt(0).toUpperCase() + propertyType.slice(1),
    },
    {
      title: 'Application Status',
      dataIndex: ['CofO', 'status'],
      key: 'status',
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'Under Review', value: 'under_review' },
        { text: 'Approved', value: 'approved' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Assigned', value: 'assigned' },
      ],
      onFilter: (value, record) => record.CofO.status === value,
      render: (status) => getStatusTag(status, 'status'),
      width: 140,
    },
    {
      title: 'Payment Status',
      dataIndex: ['CofO', 'paymentStatus'],
      key: 'paymentStatus',
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'Paid', value: 'paid' },
      ],
      onFilter: (value, record) => record.CofO.paymentStatus === value,
      render: (status) => getStatusTag(status, 'payment'),
      width: 130,
    },
    {
      title: 'C of O Upload',
      dataIndex: ['CofO', 'uploadOfCofOStatus'],
      key: 'uploadOfCofOStatus',
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'Uploaded', value: 'uploaded' },
      ],
      onFilter: (value, record) => record.CofO.uploadOfCofOStatus === value,
      render: (status) => getStatusTag(status, 'upload'),
      width: 130,
    },
    {
      title: 'Submission Date',
      dataIndex: ['CofO', 'submissionDate'],
      key: 'submissionDate',
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) => new Date(a.CofO.submissionDate) - new Date(b.CofO.submissionDate),
      width: 120,
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Link to={`/dashboard/cofo/${record.CofO._id}`}>
          <Button type="primary" className="bg-blue-600" size="small">
            View Details
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title> Certificate of Occupancy | Lock.your.Land Dashboard </title>
      </Helmet>

      <div className="p-6">
        <div className="flex justify-between flex-wrap items-center mb-6">
          <Title level={4}>Certificate of Occupancy Applications</Title>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={cofos?.data || []}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ x: 1500 }}
            rowKey={(record) => record.CofO._id}
          />
        )}
      </div>
    </>
  );
};

export default CofO;
