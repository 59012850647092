import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/usersSlice';
import userReducer from '../features/user/userSlice';
import propertydocumentsReducer from '../features/propertydocuments/PropertydocumentSlice';
import propertydocumentReducer from '../features/propertydocument/propertydocumentSlice';
import landdocumentsReducer from '../features/landdocuments/landdocumentSlice';
import landdocumentReducer from '../features/landdocument/landdocumentSlice';
import surveyorsReducer from '../features/surveyors/surveyorsSlice';
import surveyorReducer from '../features/surveyor/surveyorSlice';
import agentsReducer from '../features/agents/agentsSlice';
import agentReducer from '../features/agent/agentSlice';
import paymentReducer from '../features/payments/paymentSlice';
import titlePerfectionReducer from '../features/titlePerfection/titlePerfectionSlice';
import cofoReducer from '../features/c_of_o/c_of_o_Slice';

export const store = configureStore({
  reducer: {
    adminAuth: authReducer,
    users: usersReducer,
    user: userReducer,
    propertydocuments: propertydocumentsReducer,
    propertydocument: propertydocumentReducer,
    landdocuments: landdocumentsReducer,
    landdocument: landdocumentReducer,
    surveyors: surveyorsReducer,
    agents: agentsReducer,
    surveyor: surveyorReducer,
    agent: agentReducer,
    payment: paymentReducer,
    titlePerfection: titlePerfectionReducer,
    cofo: cofoReducer,
  },
});
