import React from 'react';
import { Button, Card, Col, Descriptions, Image, Modal, Row, Select, Table, Timeline, Typography, Tag } from 'antd';
import useSingleCofO from '../hooks/useSingleCofO';
import Loader from '../components/loader/Loader';

const { Title, Text } = Typography;
const { Option } = Select;

const CofODetails = () => {
  const {
    singleCofO,
    isLoading,
    loading,
    agents,
    isModalOpen,
    setIsModalOpen,
    setSelectedAgent,
    getStatusTag,
    handleAssignAgent,
  } = useSingleCofO();

  const documentColumns = [
    {
      title: 'Document Type',
      dataIndex: 'documentType',
      key: 'documentType',
      render: (text) =>
        text
          ?.split('_')
          ?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
          ?.join(' '),
    },
    {
      title: 'Upload Date',
      dataIndex: 'uploadDate',
      key: 'uploadDate',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" href={record?.url} target="_blank">
          View Document
        </Button>
      ),
    },
  ];

  if (isLoading || !singleCofO) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  const hasApprovalDetails =
    singleCofO?.data?.CofO?.approvalDetails &&
    (singleCofO?.data?.CofO?.approvalDetails?.approvedBy || singleCofO?.data?.CofO?.approvalDetails?.approvalDate);

  const hasUploadedCofO = singleCofO?.data?.CofO?.uploadOfCofO && singleCofO?.data?.CofO?.uploadOfCofO?.url;

  const hasAdditionalNotes =
    singleCofO?.data?.CofO?.additionalNotes && singleCofO?.data?.CofO?.additionalNotes.trim().length > 0;

  const hasPaymentDue = singleCofO?.data?.CofO?.paymentDue && singleCofO?.data?.CofO?.paymentDue.trim().length > 0;

  const allDocuments = [
    { ...singleCofO?.data?.CofO?.passportPhoto, key: 'passport' },
    { ...singleCofO?.data?.CofO?.deedOfSale, key: 'deed' },
    { ...singleCofO?.data?.CofO?.surveyPlan, key: 'survey' },
    ...(singleCofO?.data?.CofO?.additionalDocuments?.map((doc, idx) => ({
      ...doc,
      key: `additional-${idx}`,
    })) || []),
  ];

  // Add uploadOfCofO to documents if it exists
  if (hasUploadedCofO) {
    allDocuments.push({
      ...singleCofO.data.CofO.uploadOfCofO,
      key: 'uploadOfCofO',
    });
  }

  return (
    <div className="p-4 md:p-6">
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <Title level={4} className="m-0 text-lg sm:text-xl break-words">
                Application Details: {singleCofO?.data?.CofO?.applicationId}
              </Title>

              {singleCofO?.data?.CofO?.status !== 'approved' && (
                <Button type="primary" className="bg-blue-600" onClick={() => setIsModalOpen(true)}>
                  Assign Agent
                </Button>
              )}
            </div>

            <Descriptions bordered column={{ xs: 1, sm: 2 }} layout="vertical" className="w-full">
              {/* Applicant Information */}
              <Descriptions.Item label="Applicant Name">
                {singleCofO?.data?.user
                  ? `${singleCofO?.data?.user?.Firstname} ${singleCofO?.data?.user?.Lastname}`
                  : 'No applicant data available'}
              </Descriptions.Item>

              <Descriptions.Item label="Property Type">
                {singleCofO?.data?.CofO?.propertyType
                  ? singleCofO?.data?.CofO?.propertyType.charAt(0).toUpperCase() +
                    singleCofO?.data?.CofO?.propertyType.slice(1)
                  : 'Not specified'}
              </Descriptions.Item>

              {/* Status Information */}
              <Descriptions.Item label="Application Status">
                {getStatusTag(singleCofO?.data?.CofO?.status, 'status')}
              </Descriptions.Item>

              <Descriptions.Item label="Payment Status">
                {getStatusTag(singleCofO?.data?.CofO?.paymentStatus, 'payment')}
              </Descriptions.Item>

              {hasPaymentDue && (
                <Descriptions.Item label="Payment Due">
                  ₦{Number(singleCofO?.data?.CofO?.paymentDue).toLocaleString()}
                </Descriptions.Item>
              )}

              {singleCofO?.data?.CofO?.uploadOfCofOStatus && (
                <Descriptions.Item label="CofO Upload Status">
                  {getStatusTag(singleCofO?.data?.CofO?.uploadOfCofOStatus, 'upload')}
                </Descriptions.Item>
              )}

              {/* Agent Information - Only show if agent exists */}
              {singleCofO?.data?.agent && (
                <>
                  <Descriptions.Item label="Agent Name">
                    {`${singleCofO?.data?.agent?.Firstname} ${singleCofO?.data?.agent?.Lastname}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Agent Email">{singleCofO?.data?.agent?.Email}</Descriptions.Item>
                  <Descriptions.Item label="Agent License">{singleCofO?.data?.agent?.AgentLicense}</Descriptions.Item>
                </>
              )}

              {/* Dates */}
              <Descriptions.Item label="Submission Date">
                {formatDate(singleCofO?.data?.CofO?.submissionDate)}
              </Descriptions.Item>
              <Descriptions.Item label="Last Updated">
                {formatDate(singleCofO?.data?.CofO?.lastUpdated)}
              </Descriptions.Item>

              {/* Approval Details - Only show if they exist */}
              {hasApprovalDetails && (
                <>
                  <Descriptions.Item label="Approval Date">
                    {formatDate(singleCofO?.data?.CofO?.approvalDetails?.approvalDate)}
                  </Descriptions.Item>
                  {singleCofO?.data?.CofO?.approvalDetails?.certificateNumber && (
                    <Descriptions.Item label="Certificate Number">
                      {singleCofO?.data?.CofO?.approvalDetails?.certificateNumber}
                    </Descriptions.Item>
                  )}
                </>
              )}

              {/* Additional Notes - Only show if they exist */}
              {hasAdditionalNotes && (
                <Descriptions.Item label="Additional Notes">
                  {singleCofO?.data?.CofO?.additionalNotes}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </Col>

        {/* Required Documents */}
        <Col xs={24}>
          <Card title="Required Documents">
            <div className="overflow-x-auto">
              <Table
                columns={documentColumns}
                dataSource={allDocuments}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Card>
        </Col>

        {/* Site Photos - Only show if they exist */}
        {singleCofO?.data?.CofO?.sitePhotos && singleCofO?.data?.CofO?.sitePhotos.length > 0 && (
          <Col xs={24}>
            <Card title="Site Photos">
              <Image.PreviewGroup>
                <Row gutter={[16, 16]}>
                  {singleCofO?.data?.CofO?.sitePhotos.map((photo, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                      <Image
                        src={photo.url}
                        alt={`Site Photo ${index + 1}`}
                        className="w-full h-40 object-cover rounded"
                      />
                    </Col>
                  ))}
                </Row>
              </Image.PreviewGroup>
            </Card>
          </Col>
        )}

        {/* Review Notes - Only show if they exist */}
        {singleCofO?.data?.CofO?.reviewNotes && singleCofO?.data?.CofO?.reviewNotes.length > 0 && (
          <Col xs={24}>
            <Card title="Review Notes" className="overflow-x-auto">
              <Timeline className="px-4">
                {singleCofO?.data?.CofO?.reviewNotes.map((note, index) => (
                  <Timeline.Item key={index}>
                    <Text strong className="block">
                      {note.addedBy}
                    </Text>
                    <Text className="block whitespace-pre-wrap">{note.note}</Text>
                    <Text type="secondary" className="block mt-2">
                      {new Date(note.addedOn).toLocaleString()}
                    </Text>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          </Col>
        )}

        {/* Approved Certificate of Occupancy - Only show if it exists and is uploaded */}
        {hasUploadedCofO && (
          <Col xs={24}>
            <Card title="Approved Certificate of Occupancy">
              <div className="flex flex-col items-center sm:flex-row sm:justify-between gap-4">
                <div>
                  <Text strong className="block mb-2">
                    Document: {singleCofO?.data?.CofO?.uploadOfCofO?.documentType}
                  </Text>
                  <Text className="block">
                    Uploaded on: {formatDate(singleCofO?.data?.CofO?.uploadOfCofO?.uploadDate)}
                  </Text>
                </div>
                <Button
                  type="primary"
                  href={singleCofO?.data?.CofO?.uploadOfCofO?.url}
                  target="_blank"
                  className="bg-green-600"
                >
                  View Approved Certificate
                </Button>
              </div>
            </Card>
          </Col>
        )}
      </Row>

      {/* Agent Assignment Modal */}
      <Modal
        title="Assign Agent"
        open={isModalOpen}
        onOk={handleAssignAgent}
        confirmLoading={loading}
        okText={`${loading ? 'Please wait...' : 'Assign'}`}
        okButtonProps={{
          className: 'bg-blue-500 hover:bg-blue-600',
        }}
        onCancel={() => setIsModalOpen(false)}
        maxWidth="600px"
      >
        <Select placeholder="Select an agent" className="w-full" onChange={(value) => setSelectedAgent(value)}>
          {agents?.map((agent) => (
            <Option key={agent?._id} value={agent?._id}>
              {`${agent?.Firstname} ${agent?.Lastname} (${agent?.Email})`}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default CofODetails;
