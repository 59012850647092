import axios from 'axios';
import { API_URL } from '../api';

const getAllCofO = async (token, adminId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/cofo/admin/${adminId}`, config);
  return response.data;
};

const getSingleCofO = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/cofo/admin/${data.adminId}/cofo/${data.cofoId}`, config);
  return response.data;
};

const assignSingleCofO = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}/cofo/admin/${data.adminId}/cofo/${data.cofoId}/assign`, data, config);
  return response.data;
};

const cofoService = {
  assignSingleCofO,
  getSingleCofO,
  getAllCofO,
};
export default cofoService;
