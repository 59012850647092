import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tag, message } from 'antd';
import { assignSingleCofO, getSingleCofO } from '../features/c_of_o/c_of_o_Slice';
import { fetchAgents } from '../features/agents/agentsSlice';

const useSingleCofO = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [loading, setLoading] = useState(false);

  const { singleCofO, isLoading, isSuccess, isError, message: msg } = useSelector((state) => state.cofo);
  const { agents } = useSelector((state) => state.agents);

  useEffect(() => {
    dispatch(getSingleCofO(id));
    dispatch(fetchAgents());
  }, [dispatch, id]);

  const getStatusTag = (status, type = 'status') => {
    const statusColors = {
      status: {
        pending: 'gold',
        under_review: 'processing',
        approved: 'success',
        rejected: 'error',
        assigned: 'blue',
        awaiting: 'warning',
      },
      payment: {
        pending: 'warning',
        paid: 'success',
      },
      upload: {
        pending: 'default',
        uploaded: 'success',
      },
    };

    const statusText = status
      ?.replace(/_/g, ' ')
      ?.split(' ')
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');

    return <Tag color={statusColors[type][status]}>{statusText}</Tag>;
  };

  const handleAssignAgent = async () => {
    if (!selectedAgent) {
      message.error('Please select an agent');
      return;
    }
    setLoading(true);
    dispatch(assignSingleCofO({ cofoId: id, agentId: selectedAgent }));
  };

  useEffect(() => {
    if (isSuccess && msg === 'CofO Assigned Succesfully') {
      message.success('CofO Assigned Successfully');
      dispatch(getSingleCofO(id));
      setLoading(false);
    }

    if (isError) {
      message.error(msg);
      setLoading(false);
    }
  }, [isSuccess, isError, msg]);

  return {
    singleCofO,
    isLoading,
    loading,
    agents,
    isModalOpen,
    setIsModalOpen,
    selectedAgent,
    setSelectedAgent,
    getStatusTag,
    handleAssignAgent,
  };
};

export default useSingleCofO;
